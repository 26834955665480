<template>
    <div class="content">
        <h1 class="title">{{result.title}}</h1>
        <p class="auther">视力派眼科</p>
        <div class="view" v-html="result.content">

        </div>
        <p class="time">{{result.createtime | time}}</p>
    </div>
</template>
<script>
    import moment from "moment";

    export default {
        name: "eyeView",
        data() {
            return {
                id: this.$route.query.id,
                result: {}
            }
        },
        created() {
            document.title = '新闻资讯'
            this.$get(`index/newInfo?type=1&id=${this.id}`)
                .then(res => {
                    this.result = res.data
                })
        },
        filters: {
            time(dataStr) {
                if (!dataStr || dataStr == 0) {
                    return '              -'
                } else {
                    return moment(dataStr * 1000).format('YYYY-MM-DD')
                }
            }
        },
    }
</script>


<style lang="less" scoped>
    .content {
        padding: 2.6667vw;
        width: 100vw;
        padding-bottom: 5.3333vw;
        /deep/ img {
            max-width: 100%;
        }

        /deep/ p {
            width: 100%;
            font-size: 4.7333vw !important;
            white-space:pre-wrap !important;
            line-height: 6.7333vw;
        }

        /deep/ span {
            font-size: 4.7333vw !important;
            white-space:pre-wrap !important;
            line-height: 6.7333vw;
        }
        .title{
            font-size: 6vw;
            font-weight: 500;
            line-height: 7vw;

        }
        .time{
            text-align: right;
            color: #999;
            font-size: 4vw;
            margin-bottom: 2vw;
        }
        .auther{
            text-align: right;
            font-size: 4vw;
            margin: 2.6667vw 0;
            color: #999;
        }
    }

</style>
